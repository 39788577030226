import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/UI/LoadingComp"

export default {
  name: "precondition-orders-history-table",
  components: {
    Loader
  },
  props: {
    dataTable: {
      type: Array,
    },
    current: {
      type: Number,
    },
    total: {
      type: Number,
    },
    pageSize: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      preconditionDetailsData: [],
      preconditionOrdersDetailData: [],
      isModalOpen: false,
      detailsBtnLoading: null,
      preconditionOrdersDetailColumns: [
        {
          title: this.$t("preconditionsHistory.amount"),
          key: "amount",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.amount));
          },
        },
        {
          title: this.$t("preconditionsHistory.price"),
          key: "price",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.price));
          },
        },
        {
          title: this.$t("preconditionsHistory.time"),
          key: "time",
          align: "center",
          minWidth: 180,
          sortable: true,
          render: (h, params) => {
            return h("span", {}, this.dateFormat(params.row.time));
          },
        },
        {
          title: this.$t("preconditionsHistory.turnOver"),
          key: "turnover",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.turnover));
          },
        },
      ],
      columns: [
        {
          type: "index",
          align: "center",
          minWidth: 50,
          // render: (h, params) => {
          //   return h(expandRow, {
          //     props: {
          //       skin: params.row.skin,
          //       rows: params.row.detail,
          //     },
          //   });
          // },
        },
        {
          title: this.$t("preconditionsHistory.time"),
          align: "center",
          sortable: true,
          minWidth: 200,
          key: "createdDate",
          render: (h, params) => {
            return h(
              "span",
              {},
              this.$options.filters.dateFormat(params.row.createdDate)
            );
          },
        },
        {
          title: this.$t("preconditionsHistory.pairCoin"),
          align: "center",
          key: "symbol",
          minWidth: 150,
        },
        {
          title: this.$t("preconditionsHistory.orderType"),
          minWidth: 140,
          align: "center",
          key: "type",
          render: (h, params) => {
            return h(
              "span",
              params.row.source === "STOP_LIMIT_CONDITION"
                ? this.$t("preconditionsHistory.stopLimit")
                : this.$t("preconditionsHistory.ocoStopLimit")
            );
          },
        },
        {
          title: this.$t("preconditionsHistory.tradeDirection"),
          align: "center",
          key: "direction",
          minWidth: 100,
          render: (h, params) => {
            const row = params.row;
            const className = row.direction.toLowerCase();
            return h(
              "span",
              {
                attrs: {
                  class: className,
                },
              },
              row.direction == "BUY"
                ? this.$t("preconditionsHistory.buy")
                : this.$t("preconditionsHistory.sell")
            );
          },
        },
        {
          title: this.$t("preconditionsHistory.activationPrice"),
          align: "center",
          sortable: true,
          minWidth: 160,
          key: "activationPrice",
          slot: "activationPrice",
          // render: (h, params) => {
          //   return h(
          //     "span",
          //     params.row.momentMarketPrice >= params.row.activationPrice
          //       ? this.numberWithCommas(
          //           params.row.activationPrice,
          //           this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
          //         ) +
          //           " =" +
          //           ">"
          //       : params.row.momentMarketPrice <= params.row.activationPrice
          //       ? this.numberWithCommas(
          //           params.row.activationPrice,
          //           this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
          //         ) +
          //         " =" +
          //         "<"
          //       : "--"
          //   );
          // },
        },
        {
          title: this.$t("preconditionsHistory.price"),
          align: "center",
          sortable: true,
          minWidth: 170,
          key: "orderPrice",
          render: (h, params) => {
            return h(
              "span",
              this.numberWithCommas(
                params.row.orderPrice,
                this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
              )
            );
          },
        },
        {
          title: this.$t("preconditionsHistory.amount"),
          align: "center",
          sortable: true,
          key: "amount",
          minWidth: 180,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                this.numberWithCommas(
                  params.row.amount,
                  this.getPairCoinScales[params.row.symbol].frontCoinScale
                )
              ),
              h(
                "span",
                {
                  style: {
                    fontSize: "9px",
                    marginLeft: "3px",
                  },
                },
                params.row.type === "MARKET_PRICE"
                  ? params.row.direction === "BUY"
                    ? params.row.symbol.split("/")[1]
                    : params.row.symbol.split("/")[0]
                  : params.row.symbol.split("/")[0]
              ),
            ]);
          },
        },
        {
          title: this.$t("preconditionsHistory.refCode"),
          key: "trackingCode",
          align: "center",
          minWidth: 200,
          render: (h, params) => {
            return h("div", params.row.trackingCode);
          },
        },
        {
          title: this.$t("preconditionsHistory.status"),
          key: "status",
          width: 150,
          align: "center",
          render: (h, params) => {
            const status = params.row.status;
            if (status == "COMPLETED") {
              return h(
                "span",
                {
                  style: {
                    color: "#f0a70a",
                  },
                },
                this.$t("preconditionsHistory.completed")
              );
            } else if (status == "ORDERED") {
              return h(
                "span",
                {
                  style: {
                    color: "#f0a70a",
                  },
                },
                this.$t("preconditionsHistory.ordered")
              );
            } else {
              return h(
                "span",
                {
                  style: {
                    color: "#f15057",
                  },
                },
                this.$t("preconditionsHistory.canceled")
              );
            }
          },
        },
        {
          title: this.$t("preconditionsHistory.operation"),
          key: "operation",
          slot: "operation",
          width: 180,
          align: "center",
          // render: (h, params) => {
          //   let actions = [];
          //   actions.push(
          //     h(
          //       "Button",
          //       {
          //         props: {
          //           type: "text",
          //           size: "small",
          //         },
          //         style: {
          //           border: "1px solid #f0ac19",
          //           color: "#f1ac19",
          //           // padding: "3px 10px",
          //           "border-radius": "0",
          //           fontSize: "12px",
          //         },
          //         on: {
          //           click: () => {
          //             this.getPreconditionDetails({ id: params.row.id });
          //           },
          //         },
          //       },
          //       this.$t("preconditionsHistory.details")
          //     )
          //   );
          //   return h(
          //     "div",
          //     {
          //       style: {
          //         display: "flex",
          //         justifyContent: "space-around",
          //       },
          //     },
          //     actions
          //   );
          // },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getPairCoinScales: "market/GET_PAIR_COIN_SCALES",
    }),
  },
  methods: {
    ...mapActions({
      getPreconditionOrderDetailByID:
        "exchange/GET_PRECONDITION_ORDER_DETAIL_BY_ID_SERVICE",
    }),
    dateFormat(tick) {
      return this.$options.filters.dateFormat(tick);
    },
    changePageNumber(pageIndex) {
      this.$emit("changePage", pageIndex);
    },
    getPreconditionDetails(preconditionId) {
      if (this.detailsBtnLoading !== null) return;
      this.detailsBtnLoading = preconditionId;
      this.getPreconditionOrderDetailByID({id: preconditionId}).then((response) => {
        this.preconditionDetailsData = response.data.data.precondition;
        this.preconditionOrdersDetailData = response.data.data.orders;
        this.isModalOpen = true;
      }).finally(() => {
        this.detailsBtnLoading = null;
      })
    },
  },
};
