import expandRow from "@/components/Exchange/Expand";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import PreconditionOrdersHistoryTable from "@/components/HistoryOrders/PreconditionOrdersHistoryTable/index";
import { mapActions } from "vuex";

export default {
  name: "precondition-orders-history",
  components: {
    expandRow,
    PersianDatePicker: VuePersianDatetimePicker,
    PreconditionOrdersHistoryTable,
  },
  data() {
    return {
      value: false,
      styles: {
        height: "100vh",
        overflow: "auto",
        paddingBottom: "53px",
        position: "static",
        backgroundColor: "#141e2c",
      },
      drawerWidth: window.innerWidth >= 769 ? window.innerWidth >= 1000 ? "25%" : "30%" : "100%",
      orderStatusList: [],
      loading: false,
      pageSize: 10,
      pageNo: 1,
      total: 10,
      symbol: [],
      coinScales: {},
      orderTypes: [
        {
          value: "STOP_LIMIT_CONDITION",
          text: this.$t("preconditionsHistory.stopLimit"),
        },
        {
          value: "OCO_CONDITION",
          text: this.$t("preconditionsHistory.oco"),
        },
      ],
      formItem: {
        symbol: "",
        source: "",
        direction: "",
        date: "",
        status: "",
      },
      orders: [],
    };
  },
  computed: {
    // ...mapGetters({
    //   getLang: types.auth.getters.GET_LANG,
    // }),
    // lang () {
    //   return this.getLang;
    // },
  },
  watch: {
    // lang () {
    //   this.updateLangData();
    // }
  },
  created() {
    this.formItem.date = new Date().getTime();
    this.setNavigation("nav-precondition-orders-history");
    this.getHistoryOrder();
    this.getSymbol();
    this.getOrderStatusList();
  },
  mounted() {},
  methods: {
    ...mapActions({
      setNavigation: "global/NAVIGATE",
      getPreconditionOrderStatusList:
        "exchange/GET_PRECONDITON_ORDER_STATUS_LIST_SERVICE",
      getPreconditionOrdersHistoryList:
        "exchange/GET_PRECONDITION_ORDERS_HISTORY_LIST_SERVICE",
      getSymbolsThumb: "market/GET_SYMBOL_THUMB_SERVICE",
    }),
    clearSelect(e, key) {
      e.stopPropagation()
      this.formItem[key] = ""
      if (key === 'orderType') {
        this.formItem.type = ""
        this.formItem.source = ""
      }
    },
    getOrderStatusList() {
      this.getPreconditionOrderStatusList().then((response) => {
        if (response.data.code === 0 && response.data.data.length > 0) {
          this.orderStatusList = response.data.data;
        }
      });
    },
    loadDataPage(data) {
      this.pageNo = data;
      this.getHistoryOrder();
    },
    handleSubmit() {
      this.pageNo = 1;
      this.getHistoryOrder();
      this.value = false;
    },
    handleClear() {
      this.formItem = {
        symbol: "",
        source: "",
        direction: "",
        date: new Date().getTime(),
        status: "",
      };
      this.handleSubmit();
    },
    getHistoryOrder() {
      this.loading = true;
      const {
          symbol,
          source,
          direction,
          date: rangeDate,
          status,
        } = this.formItem,
        startTime = rangeDate[0] || "",
        endTime = rangeDate[1] || "";
      let payload = {
        symbol: symbol,
        direction: direction,
        source: source,
        startTime: startTime,
        endTime: endTime,
        status: status,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      Object.keys(payload).forEach((key) => {
        if (payload[key] === "" || payload[key] === undefined) {
          delete payload[key];
        }
      });
      this.orders = [];
      this.getPreconditionOrdersHistoryList(payload)
        .then((response) => {
          let ordersHistoryData = response.data.data.content;
          let rows = [];
          if (ordersHistoryData.length > 0) {
            this.total = response.data.data.totalElements;
            for (let i = 0; i < ordersHistoryData.length; i++) {
              let row = ordersHistoryData[i];
              row.price =
                row.type === "MARKET_PRICE"
                  ? this.$t("preconditionsHistory.marketPrice")
                  : row.price;
              rows.push(row);
            }
            this.orders = rows;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSymbol() {
      this.getSymbolsThumb().then((response) => {
        if (response.data.length > 0) {
          this.symbol = response.data;
        }
      });
    },
    // updateLangData(){
    //   this.columns[1].title = this.$t("exchange.time");
    //   this.columns[2].title = this.$t("uc.finance.trade.symbol");
    //   this.columns[3].title = this.$t("uc.finance.trade.type");
    //   this.columns[4].title = this.$t("exchange.direction");
    //   this.columns[5].title = this.$t("exchange.price");
    //   this.columns[6].title = this.$t("exchange.num");
    //   this.columns[7].title = this.$t("exchange.traded");
    //   this.columns[8].title = this.$t("uc.finance.trade.turnover");
    //   this.columns[9].title = this.$t("exchange.status");
    // }
  },
};
